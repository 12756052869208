import './ScheduleTable.scss';

import { useState } from 'react';

import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import add from 'date-fns/add';
import axios from 'axios';
import _ from 'lodash';
import { queryCache } from 'react-query';
import { backendURL } from '../../App';
import { Button } from '../Button/Button';
import consoleLog from '../../helpers/consoleLog';
import { utcToZonedTime } from 'date-fns-tz/esm';
import { ButtonLoader } from '../ButtonLoader/ButtonLoader';

interface ScheduleSlot {
  time: Date;
  status: 'open' | 'busy' | 'disabled';
  data?: {
    id: number;
    call_failed: boolean;
  };
}
interface ScheduleTableProps {
  timeTableData: ScheduleSlot[];
  selectedWeekStart: Date;
  selectedWeekEnd: Date;
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({ timeTableData, selectedWeekStart, selectedWeekEnd }) => {
  const [expertTimeSlotInfo, setExpertTimeSlotInfo] = useState(Object);
  const [expertTimeSlotInfoDisplayStatus, setExpertTimeSlotInfoDisplayStatus] = useState(false);

  const saveExpertTimeSlot = time => {
    axios({
      method: 'post',
      url: `${backendURL}timetables/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        start: format(utcToZonedTime(new Date(time), 'Europe/Moscow'), 'yyyy-MM-dd HH:mm'),
      },
    })
      .then(result => {
        queryCache.invalidateQueries('timeTableData');
        consoleLog('success', 'Save time slot', result);
      })
      .catch(error => {
        consoleLog('error', 'Save time slot', error);
      });
  };

  const removeExpertTimeSlot = id => {
    axios({
      method: 'post',
      url: `${backendURL}timetable/${id}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('timeTableData');
        consoleLog('success', 'Remove time slot', result);
      })
      .catch(error => {
        consoleLog('error', 'Remove time slot', error);
      });
  };

  const showExpertTimeSlotInfo = anketa_answers_id => {
    setExpertTimeSlotInfo(null);
    axios({
      method: 'get',
      url: `${backendURL}anketa-answers/${anketa_answers_id}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        setExpertTimeSlotInfo(result.data);
        setExpertTimeSlotInfoDisplayStatus(true);
        consoleLog('success', 'Get time slot info ', result);
      })
      .catch(error => {
        consoleLog('error', 'Get time slot info ', error);
      });
  };

  const callNotHappened = anketa_answers_id => {
    axios({
      method: 'post',
      url: `${backendURL}anketa-answers/${anketa_answers_id}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        call_failed: true,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('clientAnketaData');
        queryCache.invalidateQueries('timeTableData');
        setExpertTimeSlotInfo(false);
        consoleLog('success', 'Call not happened button click', result);
      })
      .catch(error => {
        consoleLog('error', 'Call not happened button click', error);
      });
  };

  const onClick = (time, status, id, anketa_answers_id) => {
    status === 'free' && saveExpertTimeSlot(time);
    status === 'open' && removeExpertTimeSlot(id);
    status === 'busy' && showExpertTimeSlotInfo(anketa_answers_id);
  };

  const [zoomLink, setZoomLink] = useState<string | undefined>(undefined);
  const [gettingZoomLink, setGettingZoomLink] = useState(false);
  const getZoomLink = id => {
    setGettingZoomLink(true);
    axios({
      method: 'get',
      url: `${backendURL}zoom/actual-start-link/${id}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        // @ts-ignore
        setZoomLink(result.data.start_link);
        setGettingZoomLink(false);
        consoleLog('success', 'Get zoom link', result);
      })
      .catch(error => {
        setGettingZoomLink(false);
        consoleLog('error', 'Get zoom link', error);
      });
  };

  return (
    <div className='scheduleTable'>
      <div className='daysTable'>
        {Array(7)
          .fill(null)
          .map((items, i) => (
            <div className='day' key={_.uniqueId('day')}>
              <div className='dayValue'>{format(add(selectedWeekStart, { days: i }), 'dd')}</div>
              <div className='dayName'>{format(add(selectedWeekStart, { days: i }), 'EEEEEE', { locale: ru })}</div>
            </div>
          ))}
      </div>
      <div className='timeTable expertTimeTable'>
        {timeTableData.map((timeItem: any) => (
          <div
            onClick={() => onClick(timeItem.time, timeItem.status, timeItem?.data?.id, timeItem?.data?.anketa_answers_id)}
            className={
              'time ' +
              `${timeItem.status === 'free' ? 'free ' : ''}` +
              `${timeItem.status === 'open' ? 'open ' : ''}` +
              `${timeItem.status === 'busy' && timeItem.data.anketa_answers_id && timeItem.data.call_failed === false ? 'inteviewScheduled ' : ''}` +
              `${timeItem.status === 'busy' && timeItem.data.anketa_answers_id && timeItem.data.call_failed === true ? 'interviewMissed ' : ''}` +
              `${timeItem.status === 'disabled' ? 'disabled ' : ''}`
            }
            key={_.uniqueId('scheduleSlot')}
          >
            {timeItem.status === 'busy' && !timeItem?.data?.call_failed && (
              <span className='timeText'>{`${timeItem?.data?.user?.first_name} ${timeItem?.data?.user?.last_name} `}</span>
            )}
            {timeItem.status === 'busy' && timeItem?.data?.call_failed && <span className='timeText'>Звонок не состоялся</span>}
            <span className='titmeValue'>
              {'  '}
              {format(utcToZonedTime(new Date(timeItem.time), 'Europe/Moscow'), 'HH:mm')}{' '}
            </span>
          </div>
        ))}
      </div>

      {expertTimeSlotInfoDisplayStatus && expertTimeSlotInfo && (
        <div className='timeEventPopup'>
          <div
            className='close'
            onClick={() => {
              setExpertTimeSlotInfoDisplayStatus(false);
              setZoomLink(undefined);
            }}
          ></div>
          <div className='title'>{`${expertTimeSlotInfo?.user?.first_name} ${expertTimeSlotInfo?.user?.last_name}`}</div>
          <div className='time'>{format(utcToZonedTime(new Date(expertTimeSlotInfo.call_start), 'Europe/Moscow'), 'd LLLL, HH:mm', { locale: ru })}</div>
          <div className='callType'>{expertTimeSlotInfo.vc_service?.title}</div>
          <Button
            type={expertTimeSlotInfo.process_status === 4 || expertTimeSlotInfo.process_status === 5 ? 'button' : 'link'}
            href={`/expert/review/anketa/${expertTimeSlotInfo.anketa_id}/${expertTimeSlotInfo.user?.anketa_answers_id}/preview/`}
            width='100%'
            size='small'
            color='black'
            disabled={expertTimeSlotInfo.process_status === 4 || expertTimeSlotInfo.process_status === 5 ? true : false}
            text={expertTimeSlotInfo.process_status === 4 || expertTimeSlotInfo.process_status === 5 ? 'Анкета у администратора' : 'Посмотреть анкету'}
          />
          <div className='anketaTypeInfo'>
            <div className='fieldTitle'>Клиент</div>
            <div className='fieldValue'>
              {expertTimeSlotInfo?.client?.title} <img src={expertTimeSlotInfo?.client?.logo} className='clientLogo' />
            </div>
            {expertTimeSlotInfo.user.tribe_str && (
              <>
                <div className='fieldTitle'>Трайб</div>
                <div className='fieldValue'>{expertTimeSlotInfo?.user?.tribe_str}</div>
              </>
            )}
            {expertTimeSlotInfo.user.member_id && (
              <>
                <div className='fieldTitle'>ID</div>
                <div className='fieldValue'>{expertTimeSlotInfo?.user?.member_id}</div>
              </>
            )}
            {expertTimeSlotInfo.user.phone && (
              <>
                <div className='fieldTitle'>Телефон</div>
                <div className='fieldValue'>{expertTimeSlotInfo?.user?.phone}</div>
              </>
            )}
            {expertTimeSlotInfo.user.case_str && (
              <>
                <div className='fieldTitle'>Роль</div>
                <div className='fieldValue'>{expertTimeSlotInfo?.user?.case_str}</div>
              </>
            )}
            {expertTimeSlotInfo.user.status_str && (
              <>
                <div className='fieldTitle'>Сегмент</div>
                <div className='fieldValue'>{expertTimeSlotInfo?.user?.status_str}</div>
              </>
            )}
          </div>
          {expertTimeSlotInfo.vc_service?.system_name == 'skype' &&
              (
                  <div className='callLink skype'>Skype: {expertTimeSlotInfo.skype}</div>
              )}
          {((expertTimeSlotInfo.vc_service?.system_name == 'zoom' || expertTimeSlotInfo.vc_service?.system_name == 'dion') && expertTimeSlotInfo.vc_link) &&
              (
                  <a href={expertTimeSlotInfo.vc_link} target='_blank' rel='noopener noreferrer' className='callLink zoom'>
                    Ссылка на конференцию
                  </a>
              )}

          {new Date() > new Date(expertTimeSlotInfo.call_start) ? (
            <Button
              onClick={() => callNotHappened(expertTimeSlotInfo.user.anketa_answers_id)}
              type='button'
              width='100%'
              size='small'
              color='black'
              text='Звонок не состоялся'
              disabled={expertTimeSlotInfo.call_failed}
            />
          ) : (
            <Button type='button' width='100%' size='small' color='black' text='Добавить в Google Calendar' disabled />
          )}
        </div>
      )}
    </div>
  );
};
